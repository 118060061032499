<template>
    <div class="row">
        <div class="field">
            <label class="label">Дата начала</label>
            <date-component
                    v-model="from"
                    class="input form-control form-control-sm"
                    v-bind:time="false"
            ></date-component>
        </div>
        <div class="field">
            <label class="label">Дата окончания</label>
            <date-component
                    v-model="to"
                    class="input form-control form-control-sm"
                    v-bind:time="false"
            ></date-component>
        </div>
        <div class="field">
            <label class="label">Только бейдж</label><br/>

            <label class="switch " style="float: left">
                <input type="checkbox" class="primary" v-model="onlyBage">
                <span class="slider round"></span>
            </label>

        </div>
        <div class="field" style="padding-top: 1rem">
            <button type="submit" class="btn btn-primary" @click="fetchData">Показать</button>
        </div>

        <div class="col-md-10 offset-md-1" style="margin-top: 20px">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th scope="col">№№</th>
                    <th scope="col">UID</th>
                    <th scope="col">дата</th>
                    <th scope="col" title="Бейдж"><i class="fa fa-id-card"></i></th>
                    <th scope="col" title="Рекомендация проверена"><i class="fa fa-file-text-o"></i></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in smartData"
                    v-bind:data-uid="item.uid"
                    v-bind:key="item.id"
                    class="hover"
                >
                    <td>{{index+1}}</td>
                    <td>{{item.uid}}</td>
                    <td>{{item.dt_arrived?$moment(item.dt_arrived).format('DD.MM.YYYY HH:mm:ss'):'-'}}</td>
                    <td><i v-if="item.bage" class="fa fa-check"></i></td>
                    <td><i v-if="item.recommendation" class="fa fa-check"></i></td>
                    <td>{{item.paid}}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <th colspan="3"></th>
                    <th>{{bageCount}}</th>
                </tr>
                </tfoot>
            </table>
        </div>

    </div>

</template>

<script>

  export default {
    name: 'expressStatistic',
    data: function () {
      return {
        data: [],
        from: new Date(),
        to: new Date(),
        onlyBage: true
      }
    },
    computed: {
      bageCount: function () {
        return this.data.reduce((sum, item) => sum + Number.parseFloat(item.bage), 0);
      },
      smartData: function () {
        if (!this.onlyBage) {
          return this.data;
        }
        return this.data.filter(item => item.bage !== 0)
      }
    },
    methods: {
      async fetchData() {
        this.$loading(true);
        const from = this.$moment(this.from).format('YYYY-MM-DD 00:00:00');
        const to = this.$moment(this.to).format('YYYY-MM-DD 23:59:59');
        try {
          // console.log(`/registered?from=${from}&to=${to}`);
          const { body } = await this.getData(`/expressStatistic?from=${from}&to=${to}`);
          console.log(body);
          this.data = body;
        } catch (e) {
          console.error(e);
        }

        this.$loading(false);
      }

    }
  }
</script>

<style lang="scss" scoped>
    @import '~bootstrap/scss/bootstrap.scss';

    .fa.fa-check {
        color: green;
    }

    .field {
        padding: 0;
        @extend .col-10, .offset-1;
        @extend .col-md-2;
        @extend .col-lg-2;
        @extend .col-xl-2, .offset-xl-1;
    }

</style>
