<template>
  <div class="row">
    <div class="onerow card ">
      <div class="card-header">
        <i @click="request" style="cursor: pointer" class="fa fa-refresh "></i>
        Экспресс регистрация, UID = {{ uid }}
      </div>
      <div class="card-body">
        <div v-if="error" class="alert alert-danger" @click="clearError">
          {{ error }}
        </div>
        <div class="result">
          <div style="margin: 0">
            <span class="fullname">{{ userData.fullname }}, {{ uid }}</span>
          </div>
          <div>
            <span
              class="day "
              v-for="day in retreatData.dayCount"
              :key="day"
              :class="{
                curday: retreatData.curday === day,
                active: isDayActive(day),
                hatchingGreen: isDayInvoiced(day)
              }"
            ></span>
          </div>
          <div>
            <span
              class="day"
              v-for="night in retreatData.nightCount - 1"
              :key="night"
              :class="{
                active: isNightActive(night),
                hatchingGreen: isNightInvoiced(night)
              }"
            ></span>
          </div>
          <div v-if="userData.borg > 0" class="red">
            Долг: {{ userData.borg }}
          </div>

          <div
            v-if="needRecommendation()"
            style="text-align: left; padding-top: 5px"
          >
            <div
              style="display: inline-block;float: left;padding: 0 10px 0 10px"
            >
              Рекомендация проверена
            </div>

            <label class="switch" style="float: left">
              <input type="checkbox" class="info" v-model="recommendation" />
              <span class="slider round"></span>
            </label>
          </div>

          <table class="table table-bordered" style="margin-top: 5px">
            <tr
              v-for="invoice in invoices"
              :key="invoice.uuid"
              :class="invoice.transactionStatus"
            >
              <td align="left">
                {{ $moment(invoice.createdDate).format('DD.MM HH:mm') }}
              </td>
              <td align="right">{{ Number.parseInt(invoice.amount) }}</td>

              <td><i v-if="invoice.bage" class="fa fa-id-card"></i></td>
              <td v-if="invoice.dt_arrived" align="left">
                {{ $moment(invoice.dt_arrived).format('DD.MM HH:mm') }}
                <span
                  class="d-none d-md-none d-lg-inline-block  float-right"
                  style="padding-right:5px"
                  >{{ invoice.transactionStatus }}</span
                >
              </td>
              <td v-else>
                <input
                  type="radio"
                  v-if="invoice.transactionStatus === 'approved'"
                  name="useIt"
                  :value="invoice.uuid"
                  v-model="uuid"
                />

                <span
                  class="d-none d-md-none d-lg-inline-block float-right"
                  style="padding-right:5px"
                  >{{ invoice.transactionStatus }}</span
                >
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div
        v-if="status === 'success'"
        class="alert alert-success"
        @click="clearError"
      >
        <h4>Успешно!</h4>
        <button class="btn btn-success">Продолжить</button>
      </div>
      <div v-else class="card-footer">
        <div class="row">
          <div class="col-5 ">
            <button class="btn btn-secondary " type="reset" @click="clearError">
              Очистить
            </button>
          </div>
          <div class="col-5">
            <button
              class="btn btn-primary"
              type="button"
              @click="register"
              :disabled="!canRegister()"
            >
              Регистрация
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'checkExpress',
  data: function() {
    return {
      uid: undefined,
      error: null,
      status: 'init',
      invoices: null,
      retreatData: {},
      userData: {},
      recommendation: false,
      recommendationRequired: true,
      fuuid: null,
      selectedInvoice: null
    };
  },
  methods: {
    request: async function() {
      this.error = null;
      this.uuid = null;
      this.selectedInvoice = null;

      this.$loading(true);
      this.retreatData = {};
      this.userData = {};
      this.userRet = {};
      this.invoices = {};
      let church_id;
      try {
        [
          {
            body: { retreatData: this.retreatData, userData: this.userData }
          },
          { body: this.invoices },
          { body: this.userRet },
          {
            body: { church_id }
          }
        ] = await Promise.all([
          this.getData(`/reg/${this.uid}`),
          this.getData(`/users/${this.uid}/invoices`),
          this.getData(`/users/${this.uid}/ret`),
          this.getData(`/users/${this.uid}`)
        ]);
      } catch (e) {
        this.$loading(false);
        console.warn(e);
        if (e.status === 404) {
          this.error = 'UID не найден';
        }
      }
      this.recommendationRequired = church_id !== 3;
      this.retreatData.nightCount = this.retreatData.dayCount + 1;
      this.$loading(false);
    },
    isDayActive(day) {
      if (!this.userData.days || this.userData.days.length === 0) {
        return false;
      }
      return this.userData.days
        .split(/;/)
        .map(item => Number.parseInt(item))
        .some(item => item === day);
    },
    isNightActive(night) {
      if (!this.userData.nights || this.userData.nights.length === 0) {
        return false;
      }
      return this.userData.nights
        .split(/;/)
        .map(item => Number.parseInt(item))
        .some(item => item === night);
    },
    isDayInvoiced(day) {
      if (!this.selectedInvoice) {
        return false;
      }
      const days = this.selectedInvoice.days
        .split(/;/)
        .map(item => Number.parseInt(item));
      return days.includes(day);
    },
    isNightInvoiced(night) {
      if (!this.selectedInvoice) {
        return false;
      }
      const nights = this.selectedInvoice.nights
        .split(/;/)
        .map(item => Number.parseInt(item));
      return nights.includes(night);
    },
    clearError() {
      this.$router.push('/checkExpress');
    },
    async register() {
      try {
        let url = `/invoices/${this.uuid}/apply`;
        if (this.recommendationRequired) {
          url += `?recommendation=${this.recommendation}`;
        }
        await this.getData(url);
        this.clearError();
      } catch (e) {
        console.error(e);
        this.error = e.message;
      }
    },
    needRecommendation() {
      if (!this.recommendationRequired) {
        return false;
      }
      if (!this.userRet || Object.keys(this.userRet).length === 0) {
        return false;
      }
      return !(
        this.userRet.recommendationChecker === -1 ||
        this.userRet.recommendationCheckDate
      );
    },
    canRegister() {
      return this.uuid && (!this.needRecommendation() || this.recommendation);
    }
  },
  async mounted() {
    this.uid = Number.parseInt(this.$route.params.uid);
    await this.request();
  },
  computed: {
    uuid: {
      set(val) {
        this.fuuid = val;
        if (!val) {
          return;
        }
        [this.selectedInvoice] = this.invoices.filter(
          item => item.uuid === val
        );
        console.log(this.uuid);
      },
      get() {
        return this.fuuid;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../variables.scss';

.approved {
  background-color: #ddffdd;
}

.pending {
  background-color: beige;
}

.arrived {
  background-color: orange;
}

.declined {
  background-color: #c8cbcf;
}

.red {
  color: orangered;
  font-size: 1.2rem;
}

.recommendation {
  background-color: #fff9b9;
}

.fullname {
  text-transform: uppercase;
}

.bage {
  text-transform: uppercase;
  color: #495057;
  font-weight: bold;
}

.result {
  text-align: center;

  & div {
    margin-top: 5px;
  }
}

.day {
  display: inline-block;
  height: 25px;
  width: 25px;
  border: 1px #ccc dotted;
  text-align: center;
  vertical-align: middle;
}

.curday {
  border: 1px #000 solid;
}

.active {
  background-color: $active !important;
}

.card-body {
  padding: 1rem;
}

.fullname {
  padding: 4px;
}

.refunded {
  text-decoration: line-through;
  color: #b21f2d;
}
input[type='radio'] {
  margin-left: 5px;
  margin-top: 4px;
  float: left;
}
.hatchingGreen {
  background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0) 49.9%,
      $active 49.9%,
      $active 60%,
      rgba(0, 0, 0, 0) 60%
    ),
    linear-gradient(45deg, $active 10%, rgba(0, 0, 0, 0) 10%);
  background-size: 0.5em 0.5em;
}
</style>
