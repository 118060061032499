<template>
    <form class="row" @submit.prevent="request">
        <div class="row onerow card ">
            <div class="card-header">Экспресс регистрация</div>
            <div class="card-body">

                <label class="label" for="uid">UID</label>
                <input id="uid" class="form-control" type="number" ref="uid"
                       v-model.number="uid"/>

            </div>

            <div class="card-footer">

                <button class="btn btn-primary" type="submit">Запрос</button>
            </div>

        </div>
    </form>
</template>

<script>
  export default {
    name: 'checkExpress',
    data: function () {
      return {
        uid: undefined
      }
    },
    methods: {
      request: function () {
        const uid = Number.parseInt(this.uid);
        if (Number.isFinite(uid)) {
          this.$router.push(`/checkExpress/${uid}`);
          return;
        }
        this.$refs.uid.focus();
      }
    },
    mounted() {
      this.uid = null;
      this.$refs.uid.focus();
    }
  }
</script>

<style lang="scss" scoped>

</style>
